import {
  ISettingsParamsBooleansKeys,
  ISettingsParamsCustomsKeys,
  ISettingsParamsTextKeys,
} from '../../components/BookOnline/settingsParams';
import {
  BookingsQueryParams,
  WixOOISDKAdapter,
} from '@wix/bookings-adapter-ooi-wix-sdk/dist/src/WixOOISDKAdapter';
import {
  DisplayServicesByOptions,
  ServiceListLayoutOptions,
} from '../../types/types';
import { Experiments } from '@wix/yoshi-flow-editor';

export type PublicDataOverrides = {
  displayServicesBy?: DisplayServicesByOptions;
  serviceListLayout?: ServiceListLayoutOptions;
  isTitleVisible?: boolean;
  services?: string[];
  categories?: string[];
  locations?: string[];
  titleText?: string;
};

export const mapPublicDataOverridesToPublicData = (
  publicDataOverrides?: string,
  serviceListTitle?: string,
): PublicDataOverrides & {
  titleText?: string;
} => {
  const publicData = {
    [ISettingsParamsTextKeys.titleText]: serviceListTitle,
  };
  if (!publicDataOverrides) {
    return publicData;
  }
  const publicDataOverridesFromUrl: PublicDataOverrides =
    JSON.parse(publicDataOverrides);
  return {
    ...(publicDataOverridesFromUrl.displayServicesBy && {
      [ISettingsParamsCustomsKeys.displayServicesBy]:
        publicDataOverridesFromUrl.displayServicesBy,
    }),
    ...(publicDataOverridesFromUrl.serviceListLayout && {
      [ISettingsParamsCustomsKeys.serviceListLayout]:
        publicDataOverridesFromUrl.serviceListLayout,
    }),
    ...('isTitleVisible' in publicDataOverridesFromUrl && {
      [ISettingsParamsBooleansKeys.isTitleVisible]:
        publicDataOverridesFromUrl.isTitleVisible,
    }),
    ...publicData,
  };
};

export const isAnywhereFlow = (
  wixSdkAdapter: WixOOISDKAdapter,
  experiments?: Experiments,
): boolean => {
  const isAnywherePublicDataOverridesEnabled =
    experiments &&
    experiments.enabled('specs.bookings.anywherePublicDataOverrides');
  const dayFulQueryParam =
    isAnywherePublicDataOverridesEnabled &&
    wixSdkAdapter.getUrlQueryParamValue('dayful' as BookingsQueryParams);
  return dayFulQueryParam === 'sch';
};
